import styled from "styled-components";

import { CustomLink } from "shared/CustomLink";

export const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
`;

export const BreadcrumbList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 15px 0;
`;

export const BreadcrumbListItem = styled.li<{ fontSize: number }>`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  text-transform: uppercase;
  line-height: normal;
  list-style: none;
  display: flex;
  align-items: center;
`;

export const LastBreadcrumbListItem = styled.span`
  color: ${({ color }) => color};
  text-transform: uppercase;
  display: inline-block;
  white-space: nowrap;
  line-height: 13px;
  max-width: 30ch;
  vertical-align: text-bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`;

export const BreadcrumbIconWrapper = styled.div`
  width: 16px;
  display: inline-flex;
  justify-content: center;
`;

export const BreadcrumbLink = styled(CustomLink)<{ color: string }>`
  display: inline-block;
  white-space: nowrap;
  line-height: 13px;
  vertical-align: text-bottom;
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  max-width: 200px;
  font-weight: 500;

  color: ${({ color }) => color};
`;
