import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
`;

export const TopButtonWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 40px;
  text-align: center;
`;

export const TopButton = styled.button`
  width: 150px;
  height: 40px;
`;

export const NavigationWrapper = styled.div`
  width: 25%;
  padding-right: 15px;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const PageWrapper = styled.div`
  width: 75%;
  padding-left: 15px;

  @media screen and (max-width: 1080px) {
    width: 100%;
    padding: 0;
  }
`;
