import styled from "styled-components";

import { CustomLink } from "shared/CustomLink";

export const Banner = styled.div<{ isgradient: string }>`
  background: ${({ bgColor, isGradient }) =>
    isGradient === "true"
      ? `linear-gradient(90deg, ${bgColor} 60%, rgba(255, 255, 255, 0) 100%)`
      : bgColor};

  padding: 30px;
  height: 104px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const SubTitle = styled.h1<{ fontsize: number; color: string }>`
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: ${({ fontsize }) => `${fontsize}px`};
  line-height: 32px;
  margin: 0;
  text-shadow: 3px 3px 13.5px rgba(37, 37, 37, 0.3);
  color: ${({ color }) => color};
`;

export const SubTitleSecond = styled.h1<{ fontsize: number; color: string }>`
  font-family: "Rubik, sans-serif";
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: ${({ fontsize }) => `${fontsize}px`};
  color: ${({ color }) => color};
  text-shadow: 3px 3px 13.5px rgba(37, 37, 37, 0.3);
  text-align: center;
  margin: -20px 0;
`;

export const Wrapper = styled.div``;

export const Inner = styled.div<{ isbackground: string }>`
  background-image: ${({ isbackground, backgroundImage }) =>
    isbackground ? backgroundImage : "none"};

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1280px;
  padding: ${({ isbackground }) => (isbackground ? "0" : "0 20px")};
  margin: 0 auto;
  position: relative;

  @media only screen and (max-width: 1080px) {
    background-image: none;
  }
`;

export const Row = styled.div<{ isImageBackground: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${({ isImageBackground }) =>
    `30px ${isImageBackground ? "30px" : "0"}`};
  align-items: center;
  position: relative;

  @media only screen and (max-width: 600px) {
    padding: ${({ isImageBackground }) =>
      `30px ${isImageBackground ? "20px" : "0"} 90px `};
  }
`;

export const BipWrapper = styled.div<{ bgColor: string }>`
  background: ${({ bgColor }) => bgColor};
  padding: 10px;
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
`;

export const Bip = styled(CustomLink)`
  background: ${({ bgColor }) => bgColor};
  padding: 10px;

  @media only screen and (max-width: 600px) {
    padding: 5px;
  }
`;

export const BipImage = styled.img`
  @media only screen and (max-width: 600px) {
    width: 154px;
    height: 34px;
  }
`;

export const RightHeaderWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const RightHeaderlogo = styled(CustomLink)`
  z-index: 0;
`;
export const MobileSearchInputWrapper = styled.div<{
  isImageBackground: boolean;
}>`
  display: none;

  padding: ${({ isImageBackground }) =>
    `70px ${isImageBackground ? "20px" : "0"} 50px`};
  max-width: 100%;
  margin: 0 auto;
  gap: 25px;

  @media screen and (max-width: 1080px) {
    display: flex;
  }

  @media screen and (max-width: 600px) {
    gap: 10px;
  }
`;

export const Title = styled.h1<{ color: string; fontSize: number }>`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
  font-weight: 400;
  letter-spacing: 1.5px;
  text-shadow: "3px 3px 13.5px rgba(37, 37, 37, 0.3)";
`;

export const ImageTitle = styled.h1<{
  fontSize: number;
  shadowColor: string;
  color: string;
}>`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
  font-weight: 700;
  margin: 0;
  text-shadow: ${({ shadowColor }) => `2px 2px 2px ${shadowColor}`};
  white-space: nowrap;
`;

export const ImageSubTitle = styled.h2`
  color: ${({ color }) => color};

  text-shadow: ${({ shadowColor }) => `3px 3px 13.5px ${shadowColor}`};
  white-space: nowrap;

  p {
    margin: 0;
  }
`;

export const TitlesWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 60px;
  text-align: center;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const Hamburger = styled.button`
  display: none;
  border: none;
  background: transparent;

  @media screen and (max-width: 1080px) {
    display: block;
  }
`;

export const HamburgerImage = styled.img`
  @media screen and (max-width: 600px) {
    height: 24px;
    width: 28px;
  }
`;

export const LogoWrapper = styled.div`
  display: none;
  width: 100%;
  justify-content: center;
  position: relative;
  right: 45px;

  @media screen and (max-width: 1080px) {
    display: flex;
  }

  @media screen and (max-width: 600px) {
    position: absolute;
    margin: 0 auto;
    right: 0;
    top: 120px;
  }
`;

export const SearchInputWrapper = styled.div`
  max-width: 80%;
  margin: 0 auto;
  padding: 47px 0 31px;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const LogoInner = styled(CustomLink)`
  &:hover {
    cursor: pointer;
  }
`;

export const Logo = styled.img`
  @media screen and (max-width: 600px) {
    width: 67px;
    height: 67px;
  }
`;

export const HomeButton = styled.button<{
  isContrast: boolean;
  borderColor: string;
  bgColorHover: string;
}>`
  height: 35px;
  border-radius: 5px;
  box-shadow: rgb(136 136 136 / 50%) 1.414px 1.414px 11.28px 0.72px;
  background: ${({ isContrast }) => (isContrast ? "transparent" : "#fff")};
  border: none;
  min-width: 40px;

  border: ${({ isContrast, borderColor }) =>
    isContrast ? `2px solid ${borderColor}` : "none"};

  &:hover {
    cursor: pointer;
    background: ${({ bgColorHover }) => bgColorHover};
  }
`;
